/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from '@emotion/styled';
import { Container, ContainerSmall } from '~/utils/styles/utils';
import WebEmail from '~/components/atoms/WebEmail/WebEmail';
import { useTranslationContext } from '~/pages/impressum';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/imprint';

const ImprintMain: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <Container>
        <ContainerSmall>
          <ContainerSmallOverwritten>
            <div
              dangerouslySetInnerHTML={{
                __html: t('ImprintContent1') || tEn('ImprintContent1'),
              }}
            />
            <WebEmail />
            <div
              dangerouslySetInnerHTML={{
                __html: t('ImprintContent2') || tEn('ImprintContent2'),
              }}
            />
            <WebEmail />
            <div
              dangerouslySetInnerHTML={{
                __html: t('ImprintContent3') || tEn('ImprintContent3'),
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: t('ImprintContent4') || tEn('ImprintContent4'),
              }}
            />
            <br />
            <p>
              <strong>Version:</strong> {process.env.NEURO_VERSION} |{' '}
              {process.env.NEURO_TIMESTAMP}
            </p>
            <br />
          </ContainerSmallOverwritten>
        </ContainerSmall>
      </Container>
    </>
  );
};

const ContainerSmallOverwritten = styled.div`
  p {
    font-size: var(--primary-font-size);
    line-height: 1;
  }
`;

export default ImprintMain;
