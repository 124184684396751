import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const WebEmail: React.FC = () => {
  const { i18n } = useTranslation();

  return (
    <>
      <p>
        {i18n.language === 'de' ? 'E-Mail:' : 'Email:'}{' '}
        <a href="mailto:info@neurozentrum-arabellapark.de">
          info@neurozentrum-arabellapark.de
        </a>
      </p>
      <p>
        {i18n.language === 'de' ? 'Webseite:' : 'Website:'}{' '}
        <a href="https://www.neurozentrum-arabellapark.de">
          www.neurozentrum-arabellapark.de
        </a>
      </p>
    </>
  );
};

export default WebEmail;
